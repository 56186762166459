import { template as template_14d094168ddc4544b1d61ba40c690925 } from "@ember/template-compiler";
const FKLabel = template_14d094168ddc4544b1d61ba40c690925(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
