import { template as template_99e6ed681a70481681b90931f71c8873 } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BackButton = template_99e6ed681a70481681b90931f71c8873(`
  <DButton
    @action={{@onGoBack}}
    @label="topic.timeline.back"
    @title="topic.timeline.back_description"
    class="btn-primary btn-small back-button"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BackButton;
