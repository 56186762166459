import { template as template_72308d1d4b6348998da6038698cd07e6 } from "@ember/template-compiler";
const FKControlMenuContainer = template_72308d1d4b6348998da6038698cd07e6(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
