import { template as template_39baf98dc7874c9f890ea97af72a3117 } from "@ember/template-compiler";
const SidebarSectionMessage = template_39baf98dc7874c9f890ea97af72a3117(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
